



































import {
	Component,
	Prop,
	Vue,
	Watch,
} from "vue-property-decorator";
import {
	apiUserSearchList,
	apiUserSetUserLabel,
} from "@/api/user/user";
@Component({
	components: {},
})
export default class LsLabelChange extends Vue {
	@Prop() value ? : number
	@Prop() userId ? : number // 用户id
	@Prop({
		default: ''
	}) title!: string //弹窗标题
	@Prop({
		default: '660px'
	}) width!: string | number //弹窗的宽度
	@Prop({
		default: '20vh'
	}) top!: string | number //弹窗的距离顶部位置
	/** S Data **/
	visible = false
	$refs!: {
		formRef: any
	}
	form = {
		user_id: '', // 用户id
		labelValue: [] // 用户选中的标签
	}
	// 选中用户标签

	// 用户选择框数据
	userLevelList = {}
	userLabelList = {}

	// 表单验证
	formRules = {
		// labelValue: [{
		// 	required: true,
		// 	message: '请选择用户标签',
		// 	trigger: 'change'
		// }],
	}
	/** E Data **/

	@Watch('userId', {
		immediate: true
	})
	getuserId(val: any) {
		// 初始值
		console.log('userId', val)
		//this.form.value = val
		this.$set(this.form, 'user_id', val)
	}

	/** S Methods **/
	// 获取多选框列表
	getUserSearchList() {
		apiUserSearchList()
			.then((res: any) => {
				this.userLevelList = res.user_level_list
				this.userLabelList = res.user_label_list
			})
	}

	// 设置用户标签
	changeUserLabel() {
		this.$refs.formRef.validate((valid: any) => {
			if (!valid) {
				return
			}
			if(!this.form.labelValue.length) {
				return this.$message.error('请选择标签')
			}
			
			apiUserSetUserLabel({
					user_id: this.userId,
					label_ids: this.form.labelValue,
				})
				.then(res => {
					console.log(res)
					this.$emit('refresh')
					this.visible = false
				})
				.catch(res => {
					console.log(res)
				})
		})
	}


	onTrigger() {
		this.getUserSearchList()
		this.visible = true;
	}

	// 关闭弹窗
	close() {
		this.visible = false;
		this.form.labelValue = []
		// 重制表单内容
	}
	/** E Methods **/

	/** S Life Cycle **/
	/** E Life Cycle **/
}
