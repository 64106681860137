






















































import {
	Component,
	Prop,
	Vue,
	Watch,
} from "vue-property-decorator";
import {
	apiSelectUserLists
} from "@/api/user/user";
import {
	RequestPaging
} from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue";
@Component({
	components: {
		LsPagination
	},
})
export default class LsUserSelect extends Vue {
	// @Prop() value ? : number
	@Prop() userId ? : number // 用户id
	@Prop({
		default: ''
	}) title!: string //弹窗标题
	@Prop({
		default: '880px'
	}) width!: string | number //弹窗的宽度
	@Prop({
		default: '10vh'
	}) top!: string | number //弹窗的距离顶部位置
	/** S Data **/
	visible = false
	$refs!: {
		formRef: any
	}

	isNameSN = '' // 0-编号， 1-昵称
	// 查询表单
	form = {
		user_id: '', // 用户id
		sn: '', // 用户编号
		nickname: '', // 用户昵称
	}
	pager: RequestPaging = new RequestPaging()
	// 被选中的列
	currentRow = {}
	// 表单验证
	formRules = {
		// labelValue: [{
		// 	required: true,
		// 	message: '请选择用户标签',
		// 	trigger: 'change'
		// }],
	}
	/** E Data **/

	@Watch('userId', {
		immediate: true
	})
	getuserId(val: any) {
		// 初始值
		// console.log('userId', val)
		//this.form.value = val
		this.$set(this.form, 'user_id', val)
	}

	// 监听用户信息查询框条件
	@Watch('isNameSN', {
		immediate: true
	})
	getChange(val: any) {
		// 初始值
		this.form.sn = ''
		this.form.nickname = ''
		// this.form.mobile = ''
	}

	/** S Methods **/
	// 触发打开弹窗
	onTrigger() {
		this.getList()
		this.visible = true;
	}

	// 关闭弹窗
	close() {
		this.visible = false;
		// 重制表单内容
		this.form.sn = ''
		this.form.nickname = ''
	}
	// 确定选中
	onSubmit() {
		if(JSON.stringify(this.currentRow) == "{}")
			return this.$message.error('请选择用户')
		if( this.currentRow == null)
			return this.$message.error('请选择用户')
			
		// console.log('确定选中',this.currentRow)
		this.$emit('getUser', this.currentRow)
		this.visible = false
	}

	// 获取用户列表
	getList(): void {
		this.pager
			.request({
				callback: apiSelectUserLists,
				params: {
					...this.form,
				},
			})
			.then((res: any) => {
			})
	}

	// 重置
	onReset() {
		this.form.sn = ''
		this.form.nickname = ''

		this.getList()
	}
	// 获取选中的列
	handleCurrentChange(val: any) {
		// console.log(val,"选中的列")
		this.currentRow = val;
	}
	/** E Methods **/

	/** S Life Cycle **/
	/** E Life Cycle **/
}
