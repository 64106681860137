
import moment from 'moment';

moment.locale('zh-cn');
// 网站普遍用的格式
export const commonDate = (date: any) => {
    return moment(date).format('YYYY-MM-DD HH:MM:SS')
}
// 上传时用的时间格式
export const longNumberDate = (date: any) => {
    return moment(date).format('YYMMDDHHMMSS')
}

