






























import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'

@Component
export default class PopoverInput extends Vue {
  // props
  @Prop() changeType!: string // input-input输入框 sex-性别 time-生日/时间
  @Prop() value!: string
  @Prop({
    default: 'number'
  }) type!: 'text' | 'number'
  @Prop({
    default: '250'
  }) width!: number | string
  @Prop({
    default: false
  }) disabled!: boolean
  @Prop() tips!: string

  visible = false
  inputValue = ''

  // 带快捷时间选择器数据
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
    shortcuts: [{
      text: '今天',
      onClick(picker: any) {
        picker.$emit('pick', new Date());
      }
    }, {
      text: '昨天',
      onClick(picker: any) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', date);
      }
    }, {
      text: '一周前',
      onClick(picker: any) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', date);
      }
    }]
  }

  @Watch('value', {
    immediate: true
  })
  valueChange(val: string) {
    this.inputValue = val
  }

  // 确定事件
  handleConfirm() {
    this.visible = false
    if (this.changeType == "time") {
      this.inputValue = String((new Date(this.inputValue).getTime()) / 1000)
    }
    this.$emit('confirm', this.inputValue)
  }

  // 关闭事件
  close() {
    // 清空弹出框内容
    this.inputValue = ''

    this.visible = false
  }

  handleOpen() {
    if (this.disabled) return
    this.visible = true
  }
}
