import { render, staticRenderFns } from "./ls-label-change.vue?vue&type=template&id=083cc16d&scoped=true&"
import script from "./ls-label-change.vue?vue&type=script&lang=ts&"
export * from "./ls-label-change.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083cc16d",
  null
  
)

export default component.exports