


























































import {
	Component,
	Prop,
	Vue,
	Watch,
} from "vue-property-decorator";
import {
	apiUseradjustFirstLeader,
} from "@/api/user/user";
import LsUserSelect from '@/components/user/ls-user-select.vue';
@Component({
	components: {
		LsUserSelect,
	},
})
export default class LsInvitationChange extends Vue {
	@Prop() value ? : string // 当前上级分销商
	@Prop() nickname ? : string // 用户信息-昵称
	@Prop() sn ? : string // 用户信息-编号
	@Prop() userId ? : number // 用户id
	@Prop({
		default: ''
	}) title!: string //弹窗标题
	@Prop({
		default: '660px'
	}) width!: string | number //弹窗的宽度
	@Prop({
		default: '20vh'
	}) top!: string | number //弹窗的距离顶部位置
	/** S Data **/
	visible = false
	$refs!: {
		formRef: any
	}
	form = {
		user_id: '', // 用户id
		type: 'assign' ,// 调整方式
		first_id: '', // 分销商id
	}
	// 选中用户信息
	selectUser = {
		id: '',
		nickname: "",
		sn: "",
	}

	// 表单验证
	formRules = {
		type: [{
			required: true,
			message: '请选择',
			trigger: 'change'
		}],
	}
	/** E Data **/
	// 获取用户id
	@Watch('userId', {
		immediate: true
	})
	getuserId(val: any) {
		// 初始值
		console.log('userId', val)
		//this.form.value = val
		this.$set(this.form, 'user_id', val)
	}

	/** S Methods **/
	
	// 获取选择用户
	getUser(val: any) {
		// 未选中用户不修改
		if (val == null) {
			return
		}
		this.selectUser = val
		this.form.first_id = this.selectUser.id
	}
	
	// 修改上级分销商
	onSubmit() {
		this.$refs.formRef.validate((valid: any) => {
			if (!valid) {
				return
			}
			if(this.form.first_id == '' && this.form.type == 'assign') {
				return this.$message.error('请选择上级分销商')
			}
			apiUseradjustFirstLeader({
					...this.form
				})
				.then((res: any) => {
					console.log(res)
					this.$emit('refresh')
				})
				.catch((res: any) => {
					console.log(res)
				})
			this.visible = false
			// 重制表单内容
			this.form.first_id = '',
			this.selectUser = {
				id: '',
				nickname: "",
				sn: "",
			}
		})
	}


	onTrigger() {
		this.visible = true;
	}

	// 关闭弹窗
	close() {
		this.visible = false;
		// 重制表单内容
		this.form.first_id = '',
		this.selectUser = {
			id: '',
			nickname: "",
			sn: "",
		}
	}
	/** E Methods **/

	/** S Life Cycle **/
	/** E Life Cycle **/
}
